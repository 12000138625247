import React from "react";

export default function useWidthResizable(element: HTMLElement | null) {
  const [width, setWidth] = React.useState(0);
  React.useEffect(() => {
    if (!element) return;
    const resizeObserver = new ResizeObserver(() => {
      setWidth(element?.clientWidth || 300);
    });
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  }, [element]);

  return width;
}

export function useElementSize(element: HTMLElement | null) {
  const [size, setSize] = React.useState({ width: 0, height: 0 });
  React.useEffect(() => {
    if (!element) return;
    const resizeObserver = new ResizeObserver(() => {
      setSize({
        width: element?.clientWidth || 0,
        height: element?.clientHeight || 0,
      });
    });
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  }, [element]);

  return size;
}

import { Instance } from "mobx-state-tree";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Modal } from "semantic-ui-react";

import {
  TimelineSlot,
  TimelineSlotTemplateType,
  TimelineSlotType,
} from "@/features/timeline/models";

type UpdateTemplateInputProps = {
  slot: Instance<typeof TimelineSlot>;
  templates: TimelineSlotTemplateType[];
  open: boolean;
  onClose: () => void;
};

type Option = {
  value: string;
  label: string;
};

export default function UpdateTemplateInput(props: UpdateTemplateInputProps) {
  const { slot, templates, open, onClose } = props;
  const options = React.useMemo(() => {
    return templates.map(t => ({
      value: t.abbr,
      label: `${t.abbr}: ${t.icon} ${t.title}`,
    }));
  }, [templates]);

  const form = useForm<{ template: Option }>({
    defaultValues: {
      template: options.find(o => o.value === slot.template),
    },
  });

  const { reset } = form;
  React.useEffect(
    () => reset({ template: options.find(o => o.value === slot.template) }),
    [slot.template, reset, options]
  );

  React.useEffect(() => {
    if (open) form.setFocus("template");
  }, [form.setFocus, open]);

  const onSubmit = (data: { template: Option }) => {
    slot.setTemplate(data.template.value);
    onClose();
    form.reset();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Controller
          name="template"
          control={form.control}
          render={({ field }) => (
            <Select
              options={options}
              placeholder="Select a template"
              {...field}
              style={{ width: "100%" }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  setTimeout(() => form.handleSubmit(onSubmit)(e), 50);
                }
              }}
            />
          )}
        />
      </form>
    </Modal>
  );
}

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import classnames from "classnames";
import { Instance } from "mobx-state-tree";
import React from "react";
import mergeRefs from "react-merge-refs";

import ShortCuts, { SlotShortCuts } from "@/shortcuts";

import UpdateTemplateInput from "../templates/UpdateTemplateInput";
import { useSlotTemplatesQuery } from "./api-hooks";
import { TimelineBreak, TimelineSlot } from "./models";
import styles from "./SlotCard.module.scss";
import { useTimeline } from "./timeline";

interface SlotCardProps extends Omit<React.ComponentProps<"div">, "slot"> {
  slot: Instance<typeof TimelineSlot>;
}

function useIsFocused() {
  const [isFocused, setIsFocused] = React.useState(false);
  const onFocus = React.useCallback(() => setIsFocused(true), []);
  const onBlur = React.useCallback(() => setIsFocused(false), []);
  return { isFocused, onFocus, onBlur };
}

function focusSlot(slot: Instance<typeof TimelineSlot> | null) {
  console.log("focusSlot", slot);
  if (!slot) return;
  const element = document.querySelector<HTMLDivElement>(
    `#slot-item-${slot.id}`
  );
  element?.focus();
}

const SlotCard = React.forwardRef<HTMLDivElement, SlotCardProps>(
  (props, ref) => {
    const { slot, className, ...otherProps } = props;
    const focus = useIsFocused();
    const { data: templates } = useSlotTemplatesQuery();
    const timeline = useTimeline();
    const [isUpdatingTemplate, setIsUpdatingTemplate] = React.useState(false);
    const innerRef = React.useRef<HTMLDivElement>(null);
    const [editMode, setEditMode] = React.useState(false);
    const slotShortcuts = ShortCuts.Slot;
    React.useEffect(() => {
      if (!focus.isFocused) {
        return;
      }

      const handler = (e: KeyboardEvent) => {
        if (document.activeElement !== innerRef.current) return;
        const action = slotShortcuts[e.code];
        if (!action) return;
        if (action === SlotShortCuts.SlotIncrementDuration) {
          slot.incrementDuration(+1);
        } else if (action === SlotShortCuts.SlotDecrementDuration) {
          slot.incrementDuration(-1);
        } else if (action === SlotShortCuts.SlotFocusNext) {
          focusSlot(timeline.getNextSlot(slot));
        } else if (action === SlotShortCuts.SlotFocusPrevious) {
          focusSlot(timeline.getPreviousSlot(slot));
        } else if (action === SlotShortCuts.SlotFocusNextDay) {
          focusSlot(timeline.getNextDaySlot(slot));
        } else if (action === SlotShortCuts.SlotFocusPreviousDay) {
          focusSlot(timeline.getPreviousDaySlot(slot));
        } else if (action === SlotShortCuts.SlotDelete) {
          timeline.removeSlot(slot);
        } else if (action === SlotShortCuts.SlotStartUpdateTemplate) {
          e.preventDefault();
          setIsUpdatingTemplate(true);
        } else if (action === SlotShortCuts.SlotStartEditTitle) {
          setEditMode(true);
        }
      };
      window.addEventListener("keydown", handler);
      return () => window.removeEventListener("keydown", handler);
    }, [focus.isFocused, slotShortcuts, slot, timeline]);

    const template = templates.find(t => t.abbr === slot.template);
    return (
      <div
        ref={mergeRefs([ref, innerRef])}
        className={classnames(styles.slotCard, className)}
        {...otherProps}
        tabIndex={0}
        style={{ borderLeftColor: template?.color, ...otherProps.style }}
        onBlur={focus.onBlur}
        onFocus={focus.onFocus}
      >
        <div className={styles.slotCard__header}>
          <div style={{ width: "100%", display: "flex" }}>
            <span
              className={styles.slotCard__time}
              title={`${slot.startTime.toString()} - ${slot.endTime.toString()}`}
            >
              {slot.humanizedDuration}
            </span>
            {template && <>{template.icon}&nbsp;</>}
            {!editMode ? (
              slot.title
            ) : (
              <UpdateSlotTitle
                slot={slot}
                onFinish={({ focus = true }: { focus: boolean } = {}) => {
                  if (focus) innerRef.current?.focus();
                  setEditMode(false);
                }}
              />
            )}
          </div>
          <div className={styles.slotCard__actions}>
            <UpdateTemplateInput
              open={isUpdatingTemplate}
              slot={slot}
              templates={templates}
              onClose={() => {
                setIsUpdatingTemplate(false);
                // Refocus
                focusSlot(slot);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
);

SlotCard.displayName = "SlotCard";

function UpdateSlotTitle({
  slot,
  onFinish,
}: {
  slot: Instance<typeof TimelineSlot>;
  onFinish: () => void;
}) {
  const [title, setTitle] = React.useState(slot.title);
  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value);
    },
    []
  );
  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        e.stopPropagation();
        slot.setTitle(title);
        onFinish();
      }
      console.log(e.key);
      if (e.key === "Escape") {
        e.stopPropagation();
        onFinish();
      }
    },
    [slot, title, onFinish]
  );
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    inputRef.current?.focus();
    inputRef.current?.setSelectionRange(0, title.length);
  }, [inputRef]);

  return (
    <input
      ref={inputRef}
      className={styles.slotCard__title}
      value={title}
      style={{
        border: 0,
        outline: 0,
        flex: 1,
        padding: 0,
        lineHeight: "12px",
        backgroundColor: "transparent",
      }}
      onBlur={() => {
        slot.setTitle(title);
        onFinish({ focus: false });
      }}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
}

interface BreakCardProps extends React.ComponentProps<"div"> {
  pause: Instance<typeof TimelineBreak>;
}

const Break = React.forwardRef<HTMLDivElement, BreakCardProps>((props, ref) => {
  const { pause, className, ...otherProps } = props;
  return (
    <div
      ref={ref}
      className={classnames(styles.slotCard, styles.breakCard, className)}
      {...otherProps}
    >
      <div className={styles.slotCard__header}>
        <span className={styles.slotCard__time}>
          {pause.startTime.toString()} - {pause.endTime.toString()}
        </span>
        Break
      </div>
    </div>
  );
});

Break.displayName = "BreakCard";

export default Object.assign(SlotCard, { Break });

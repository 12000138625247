import "semantic-ui-css/semantic.min.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { User } from "firebase/auth";
import firebase from "firebase/compat/app";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AuthContext } from "@/features/auth/context";
import SignInScreen from "@/features/auth/SignInScreen";
import ShortcutsModal from "@/features/shortcuts/ShortcutsModal";
import TimelineDetail from "@/features/timeline/TimelineDetail";

import styles from "./App.module.scss";
import NavigationBar from "./components/NavigationBar";
import Sidebar from "./components/Sidebar";

function useAuth() {
  const [user, setUser] = React.useState<User | null>(null);
  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setUser(user);
    });
  }, []);

  return { signedIn: !!user, user };
}

const client = new QueryClient({});

export default function App() {
  const { signedIn, user } = useAuth();
  if (!signedIn) {
    return <SignInScreen />;
  }
  return (
    <div className={styles.app}>
      <QueryClientProvider client={client}>
        <ReactQueryDevtools />
        <AuthContext.Provider value={{ user }}>
          <BrowserRouter>
            <Sidebar />
            <main style={{ marginLeft: 150 }}>
              <ShortcutsModal />
              <NavigationBar />
              <Routes>
                <Route path="/">
                  <Route path="timeline">
                    <Route path=":timelineSlug">
                      <Route index element={<TimelineDetail />} />
                    </Route>
                  </Route>
                </Route>
              </Routes>
            </main>
          </BrowserRouter>
        </AuthContext.Provider>
      </QueryClientProvider>
    </div>
  );
}

import { Instance } from "mobx-state-tree";
import React from "react";

import { Timeline } from "./models";

const TimelineContext = React.createContext<Instance<typeof Timeline> | null>(
  null
);

export function TimelineProvider({
  children,
  timeline,
}: {
  children: React.ReactNode;
  timeline: Instance<typeof Timeline>;
}) {
  return (
    <TimelineContext.Provider value={timeline}>
      {children}
    </TimelineContext.Provider>
  );
}

export function useTimeline() {
  const context = React.useContext(TimelineContext);
  if (!context) {
    throw new Error("TimelineContext not found");
  }
  return context;
}

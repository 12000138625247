import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";

const firebaseConfig = {
  apiKey: "AIzaSyCsuAXIZeoMdjDKesZABQ1YOewY6PpioRk",
  authDomain: "workshops-1c931.firebaseapp.com",
  projectId: "workshops-1c931",
  storageBucket: "workshops-1c931.appspot.com",
  messagingSenderId: "760878927856",
  appId: "1:760878927856:web:a501b12de760b184939735",
};

export const app = firebase.initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const ui = new firebaseui.auth.AuthUI(getAuth());

import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { Icon, Menu, Sidebar } from "semantic-ui-react";
import slugify from "slugify";

import TemplateCreationModal from "@/features/templates/TemplateCreationModal";
import {
  useSlotTemplatesQuery,
  useTimelinesQuery,
} from "@/features/timeline/api-hooks";
import TimelineFormModal from "@/features/timeline/TimelineFormModal";
import useModal from "@/hooks/useModal";

import MenuItemIconButton from "./MenuItemIconButton";

export default function AppSidebar() {
  const timelinesQuery = useTimelinesQuery();
  const slotTemplatesQuery = useSlotTemplatesQuery();
  const modalSlotTemplateCreation = useModal(false);
  const modalTimelineCreation = useModal(false);
  return (
    <>
      <Sidebar
        inverted
        vertical
        visible
        as={Menu}
        animation="overlay"
        width="thin"
      >
        <Menu.Item header>
          <MenuItemIconButton
            name="add"
            onClick={modalTimelineCreation.handleOpen}
          />
          Workshops
        </Menu.Item>
        <Menu.Menu>
          {timelinesQuery.data?.map(timeline => (
            <TimelineListItem key={timeline.id} timeline={timeline} />
          ))}
        </Menu.Menu>
        <Menu.Item header>
          <MenuItemIconButton
            name="add"
            onClick={modalSlotTemplateCreation.handleOpen}
          />
          Slot Templates
        </Menu.Item>
        <Menu.Menu>
          {slotTemplatesQuery.data?.map(slotTemplate => (
            <Menu.Item key={slotTemplate.id}>
              <Icon name="square full" style={{ color: slotTemplate.color }} />
              {slotTemplate.icon} {slotTemplate.title} ({slotTemplate.abbr})
            </Menu.Item>
          ))}
        </Menu.Menu>
      </Sidebar>
      <TimelineFormModal
        open={modalTimelineCreation.isOpen}
        onClose={modalTimelineCreation.handleClose}
      />
      <TemplateCreationModal
        open={modalSlotTemplateCreation.isOpen}
        onClose={modalSlotTemplateCreation.handleClose}
      />
    </>
  );
}

function TimelineListItem({
  timeline,
}: {
  timeline: { id: string; title: string };
}) {
  const to = `/timeline/${slugify(timeline.title, { lower: true })}`;
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const [hover, setHover] = React.useState(false);
  const onEnter = () => setHover(true);
  const onLeave = () => setHover(false);

  return (
    <Menu.Item
      as={Link}
      to={to}
      active={Boolean(match)}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      {hover && <Icon name="edit" />}
      {timeline.title}
    </Menu.Item>
  );
}

import React from "react";

export default function useModal(open: boolean) {
  const [isOpen, setIsOpen] = React.useState(open);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    handleOpen,
    handleClose,
  };
}

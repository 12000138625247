import React from "react";

import ShortCuts, { StatisticsShortCuts } from "@/shortcuts";

import { useSlotTemplatesQuery } from "./api-hooks";
import { humanizeDuration } from "./helpers";
import { useTimeline } from "./timeline";
import styles from "./TimelineStatistics.module.scss";

type DurationMode = "current" | "expected";

function getTotalDuration(
  timeline: ReturnType<typeof useTimeline>,
  { kind = "current" }: { kind?: DurationMode } = {}
): number {
  if (kind === "current") {
    return timeline.templateStatistics.reduce(
      (total, [, duration]) => total + duration,
      0
    );
  }
  if (kind === "expected") {
    return timeline.expectedTotalDuration;
  }
  throw new Error(`Unknown duration mode: ${kind}`);
}

export default function TimelineStatistics() {
  const { data: templates } = useSlotTemplatesQuery();
  const timeline = useTimeline();
  const expectedTotalDuration = getTotalDuration(timeline, {
    kind: "expected",
  });
  const currentTotalDuration = getTotalDuration(timeline, { kind: "current" });
  const remainingDuration = expectedTotalDuration - currentTotalDuration;
  const [mode, setMode] = React.useState<DurationMode>("expected");
  const totalDuration =
    mode === "expected" ? expectedTotalDuration : currentTotalDuration;
  const toggleMode = React.useCallback(
    () => setMode(mode => (mode === "expected" ? "current" : "expected")),
    []
  );
  const statisticsShortcuts = ShortCuts.Statistics;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      toggleMode();
    }
  };

  const handleGlobalKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      const action = statisticsShortcuts[event.code];
      if (action === StatisticsShortCuts.ToggleMode) {
        toggleMode();
      }
    },
    [toggleMode, statisticsShortcuts]
  );

  React.useEffect(() => {
    document.addEventListener("keydown", handleGlobalKeyDown);
    return () => document.removeEventListener("keydown", handleGlobalKeyDown);
  }, [handleGlobalKeyDown]);

  return (
    <div
      role="button"
      tabIndex={0}
      className={styles.statistics}
      onClick={toggleMode}
      onKeyDown={handleKeyDown}
    >
      <div className={styles.statistics_bar}>
        {timeline.templateStatistics.map(([abbr, duration]) => {
          const template = templates.find(t => t.abbr === abbr);
          const percent = (duration / totalDuration) * 100;
          return (
            <div
              key={abbr}
              style={{ backgroundColor: template?.color, width: `${percent}%` }}
            ></div>
          );
        })}
      </div>
      <div className={styles.statisticsLegend}>
        {timeline.templateStatistics.map(([abbr, duration]) => {
          const template = templates.find(t => t.abbr === abbr);
          const percent = (duration / totalDuration) * 100;
          return (
            <div key={abbr} className={styles.statisticLegend}>
              <div
                style={{ backgroundColor: template?.color }}
                className={styles.statisticLegendDot}
              ></div>
              {template?.title || "Other"} {Math.round(percent)}% (
              {humanizeDuration(duration)})
            </div>
          );
        })}
        {mode === "expected" && remainingDuration > 0 && (
          <div className={styles.statisticLegend}>
            <div
              style={{ backgroundColor: "#ccc" }}
              className={styles.statisticLegendDot}
            ></div>
            To do{" "}
            {Math.round((remainingDuration / expectedTotalDuration) * 100)}% (
            {humanizeDuration(remainingDuration)})
          </div>
        )}
      </div>
    </div>
  );
}

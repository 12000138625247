import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Form, Input } from "semantic-ui-react";

import { useSlotTemplatesQuery } from "./api-hooks";
import { parseCommand } from "./parser";
import styles from "./SlashForm.module.scss";
import { useTimeline } from "./timeline";

type SlashFormData = {
  command: string;
};

export default function SlashForm() {
  const { data: templates } = useSlotTemplatesQuery();
  const timeline = useTimeline();
  const form = useForm<SlashFormData>({
    defaultValues: {
      command: "",
    },
  });
  React.useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.code !== "Slash") return;
      if (e.target instanceof HTMLInputElement) return;
      e.preventDefault();
      e.stopPropagation();
      form.setFocus("command");
    };
    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  const placeholder = `Type "/" to start`;

  const onSubmit = (data: SlashFormData) => {
    const slot = parseCommand(data.command, { templates });
    const created = timeline.addSlot(slot);
    form.reset();
    // FIXME: we should find something less hackish to scroll in and focus
    // newly created slot
    setTimeout(() => {
      const slot = document.getElementById(`slot-item-${created.id}`);
      if (!slot) return console.error("Could not find slot item");
      slot.scrollIntoView({ behavior: "smooth" });
      slot.focus();
    }, 50);
  };

  return (
    <div className={styles.slashForm}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <Form.Field>
          <Controller
            name="command"
            control={form.control}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                placeholder={placeholder}
                className={styles.input}
                size="massive"
                onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
                  e.stopPropagation();
                }}
              />
            )}
          />
        </Form.Field>
      </Form>
    </div>
  );
}

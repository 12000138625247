import { User } from "firebase/auth";
import React from "react";

export const AuthContext = React.createContext<{ user: User | null }>({
  user: null,
});

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  const { user } = context;
  if (!user) {
    throw new Error("useAuth must be used with a valid user");
  }

  return { user, userId: user.uid };
}

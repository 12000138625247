import React from "react";
import { Modal } from "semantic-ui-react";

import useModal from "@/hooks/useModal";
import { ShortCuts } from "@/shortcuts";

export default function ShortcutsModal() {
  const modal = useModal(false);
  const { handleOpen } = modal;
  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "?") {
        handleOpen();
        e.stopPropagation();
        e.preventDefault();
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleOpen]);

  // TODO: better display
  return (
    <Modal closeIcon open={modal.isOpen} onClose={modal.handleClose}>
      <Modal.Header>Shortcuts</Modal.Header>
      <Modal.Content>
        <>
          <p>
            <strong>Slot</strong>
          </p>
          {Object.entries(ShortCuts.Slot).map(([keyCode, action]) => (
            <p key={keyCode}>
              {keyCode} {action}
            </p>
          ))}
        </>
      </Modal.Content>
    </Modal>
  );
}

import {
  getParent,
  getSnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import slugify from "slugify";
import { v4 as uuidv4 } from "uuid";

import { humanizeDuration } from "./helpers";
import Time from "./time";

export const TimeModel = types.custom({
  name: "Time",
  fromSnapshot(snapshot: string) {
    return new Time(snapshot);
  },
  toSnapshot(model: Time) {
    return model.toString();
  },
  isTargetType(value: string | Time) {
    return value instanceof Time;
  },
  getValidationMessage(snapshot: string) {
    if (/\d\d:\d\d/.test(snapshot)) return "";
    return `'${snapshot}' doesn't look like a valid time`;
  },
});

export const SlotMinDuration = 10;
export const TimelineBreak = types
  .model("TimelineBreak", {
    id: types.optional(types.identifier, uuidv4),
    startTime: TimeModel,
    endTime: TimeModel,
  })
  .views(self => ({
    get duration() {
      return self.endTime.diff(self.startTime);
    },
  }));

export type TimelineBreakType = SnapshotIn<typeof TimelineBreak>;

export const TimelineSlotTemplate = types.model("TimelineSlotTemplate", {
  abbr: types.identifier,
  title: types.string,
  duration: types.number,
  icon: types.string,
  color: types.string,
});

export type TimelineSlotTemplateType = SnapshotOut<typeof TimelineSlotTemplate>;

export const TimelineSlot = types
  .model("TimelineSlot", {
    id: types.optional(types.identifier, uuidv4),
    day: types.optional(types.number, 1),
    startTime: TimeModel,
    duration: types.optional(types.number, SlotMinDuration),
    title: types.string,
    template: types.maybeNull(types.string),
  })
  .views(self => ({
    get endTime() {
      return self.startTime.add(self.duration);
    },
    get humanizedDuration() {
      return humanizeDuration(self.duration);
    },
  }))
  .actions(self => ({
    incrementDuration(increment = 1) {
      const parent = getParent<Instance<typeof Timeline>>(self, 2);
      if (!parent?.minSlotTime) return;
      if (increment > 0 && self.endTime.equals(parent.endAt)) return;
      const newDuration = self.duration + increment * parent.minSlotTime;
      self.duration =
        newDuration < parent.minSlotTime ? parent.minSlotTime : newDuration;

      // Shift next events
      const items = parent.itemsPerDay[self.day - 1];
      const nextItems = items.filter(item =>
        item.startTime.isAfter(self.startTime)
      );

      nextItems.forEach((item, index) => {
        const previous = index === 0 ? self : nextItems[index - 1];
        item.setStartTime(previous.endTime.copy());
      });

      // Add back breaks
      parent.breaks.forEach(pause => {
        parent.shiftItemsForBreak(pause, self.day);
      });
    },
    setTitle(title: string) {
      self.title = title;
    },
    setTemplate(template: string) {
      self.template = template;
    },
    setStartTime(time: Time) {
      self.startTime = time;
    },
  }));

export type TimelineSlotType = SnapshotIn<typeof TimelineSlot>;

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
type LayoutItem = { i: string; x: number; y: number; w: number; h: number };

export const Timeline = types
  .model("Timeline", {
    id: types.optional(types.identifier, uuidv4),
    userId: types.string,
    slug: types.string,
    title: types.string,
    days: types.optional(types.number, 1),
    items: types.array(TimelineSlot),
    startAt: types.optional(TimeModel, "09:00"),
    endAt: types.optional(TimeModel, "17:00"),
    breaks: types.array(TimelineBreak),
    minSlotTime: types.optional(types.number, SlotMinDuration),
  })
  .views(self => ({
    currentEndTimeForDay(day: number) {
      const dayItems = self.items.filter(item => item.day === day);
      if (!dayItems.length) return self.startAt;
      const dayEnd = dayItems.reduce((latestSlot, item) => {
        return latestSlot.endTime.isBefore(item.endTime) ? item : latestSlot;
      });
      const breakEnd = self.breaks.find(breakItem =>
        breakItem.endTime.isAfter(dayEnd.endTime)
      );
      return breakEnd ? breakEnd.endTime : dayEnd.endTime;
    },
    get itemsPerDay() {
      const itemsPerDay = [];
      for (let i = 1; i <= self.days; i++) {
        itemsPerDay.push(
          self.items
            .filter(item => item.day === i)
            .sort((u, v) => {
              return u.startTime.isBefore(v.startTime) ? -1 : 1;
            })
        );
      }
      return itemsPerDay;
    },
  }))
  .views(self => ({
    get itemsWithBreaksPerDay(): Instance<
      typeof TimelineSlot | typeof TimelineBreak
    >[][] {
      const slotsPerDay = self.itemsPerDay;
      const itemsPerDay = new Array(self.days).fill(0);
      for (let day = 1; day <= self.days; day++) {
        const items = slotsPerDay[day - 1];
        itemsPerDay[day - 1] = [...items, ...self.breaks].sort((u, v) => {
          return u.startTime.isBefore(v.startTime) ? -1 : 1;
        });
      }
      return itemsPerDay;
    },
  }))
  .views(self => ({
    get emptySpots(): { day: number; startTime: Time; duration: number }[] {
      const emptySpots = [];
      for (let i = 1; i <= self.days; i++) {
        const dailyItems = self.itemsWithBreaksPerDay[i - 1];
        let startTime = self.startAt;
        for (const item of dailyItems) {
          if (item.startTime.isAfter(startTime)) {
            emptySpots.push({
              day: i,
              startTime,
              endTime: item.startTime,
              duration: item.startTime.diff(startTime),
            });
          }
          startTime = item.endTime;
          if (item === dailyItems[dailyItems.length - 1]) {
            if (startTime.isBefore(self.endAt)) {
              emptySpots.push({
                day: i,
                startTime,
                endTime: self.endAt,
                duration: self.endAt.diff(startTime),
              });
            }
          }
        }
      }
      return emptySpots;
    },
  }))
  .views(self => ({
    get layout() {
      return [
        ...self.items.map(item => ({
          i: item.id,
          x: item.day - 1,
          y:
            (item.startTime.toMinutes() - self.startAt.toMinutes()) /
            self.minSlotTime,
          w: 1,
          h: item.duration / self.minSlotTime,
        })),
        ...self.breaks.map(item => ({
          i: item.id,
          x: 0,
          y:
            (item.startTime.toMinutes() - self.startAt.toMinutes()) /
            self.minSlotTime,
          w: self.days,
          h:
            (item.endTime.toMinutes() - item.startTime.toMinutes()) /
            self.minSlotTime,
          static: true,
        })),
      ];
    },
    getNextSlot(
      slot: Instance<typeof TimelineSlot>
    ): Instance<typeof TimelineSlot> | null {
      const items = self.itemsPerDay[slot.day - 1];
      const index = items.indexOf(slot);
      if (index === -1) return null;
      if (index + 1 >= items.length) return null;
      return items[index + 1];
    },
    getPreviousSlot(
      slot: Instance<typeof TimelineSlot>
    ): Instance<typeof TimelineSlot> | null {
      const items = self.itemsPerDay[slot.day - 1];
      const index = items.indexOf(slot);
      if (index === -1) return null;
      if (index - 1 < 0) return null;
      return items[index - 1];
    },
    getNextDaySlot(
      slot: Instance<typeof TimelineSlot>
    ): Instance<typeof TimelineSlot> | null {
      if (slot.day >= self.days) return null;
      const items = self.itemsPerDay[slot.day];
      const index = items.findIndex(item =>
        item.startTime.isBeforeOrEquals(slot.startTime)
      );
      if (index === -1) return null;
      return items[index];
    },
    getPreviousDaySlot(
      slot: Instance<typeof TimelineSlot>
    ): Instance<typeof TimelineSlot> | null {
      if (slot.day <= 1) return null;
      const items = self.itemsPerDay[slot.day - 2];
      const index = items.findIndex(item =>
        item.startTime.isBeforeOrEquals(slot.startTime)
      );
      if (index === -1) return null;
      return items[index];
    },
    get templateStatistics() {
      const templateMap = new Map<string, number>();
      self.items.forEach(item => {
        const { template } = item;
        const key = template || "";
        const sum = templateMap.get(key) || 0;
        templateMap.set(key, sum + item.duration);
      });
      return [...templateMap.entries()];
    },
    getNextEmptySpot(duration: number) {
      const emptySpots = self.emptySpots;
      for (const emptySpot of emptySpots) {
        if (emptySpot.duration >= duration) {
          return { day: emptySpot.day, startTime: emptySpot.startTime };
        }
      }
      return { day: self.days, startTime: self.endAt };
    },
    get totalDayDuration() {
      return self.endAt.diff(self.startAt);
    },
    get totalDuration() {
      return self.items.reduce((sum, item) => sum + item.duration, 0);
    },
    get expectedTotalDuration() {
      const dayDuration = self.endAt.diff(self.startAt);
      const breakDuration = self.breaks.reduce(
        (sum, item) => sum + item.duration,
        0
      );
      return dayDuration * self.days - breakDuration * self.days;
    },
  }))
  .actions(self => ({
    update(attributes: Partial<{ title: string; days: number }>) {
      if (attributes.title) self.title = attributes.title;
      if (attributes.days) self.days = attributes.days;
    },
    addSlot(item: Omit<TimelineSlotType, "startTime" | "day">) {
      const { duration, ...rest } = item;
      const nextEmptySlot = self.getNextEmptySpot(duration || self.minSlotTime);
      self.items.push({
        ...nextEmptySlot,
        ...rest,
      });
      const created = self.items.at(-1);
      if (!created) throw new Error("Failed to create slot");
      return created;
    },
    updateSlotWithLayout(item: LayoutItem) {
      const slot = self.items.find(i => i.id === item.i);
      if (!slot) {
        // const br = self.breaks.find(i => i.id === item.i);
        // if (br) {
        //   br.startTime = self.startAt.add(item.y * self.minSlotTime);
        //   br.endTime = br.startTime.add(item.h * self.minSlotTime);
        // }
        return;
      }
      slot.startTime = self.startAt.add(item.y * self.minSlotTime);
      slot.duration = item.h * self.minSlotTime;
      slot.day = item.x + 1;
    },
    shiftItemsForBreak(pause: Instance<typeof TimelineBreak>, day: number) {
      const items = self.itemsPerDay[day - 1];
      const hasConflict = items.some(
        item =>
          item.startTime.isBetween(pause.startTime, pause.endTime) ||
          item.endTime.isBetween(pause.startTime, pause.endTime)
      );
      console.log(hasConflict);
      if (!hasConflict) return;
      const nextItems = items.filter(
        item =>
          item.startTime.isAfterOrEquals(pause.startTime) ||
          item.endTime.isAfter(pause.startTime)
      );
      nextItems.forEach((item, index) => {
        const previous = index === 0 ? pause : nextItems[index - 1];
        item.setStartTime(previous.endTime);
      });
    },
    removeSlot(item: Instance<typeof TimelineSlot>) {
      self.items.remove(item);
    },
  }));

export type TimelineType = SnapshotOut<typeof Timeline>;
export type TimelineIn = SnapshotIn<typeof Timeline>;

import React from "react";
import { Icon, IconProps } from "semantic-ui-react";

import styles from "./MenuItemIconButton.module.scss";

export default function MenuItemIconButton({
  onClick,
  ...props
}: { onClick: () => void } & IconProps) {
  return (
    <button type="button" className={styles.button} onClick={onClick}>
      <Icon {...props} />
    </button>
  );
}

import { Instance } from "mobx-state-tree";
import React from "react";

import { useSlotTemplatesQuery } from "./api-hooks";
import { TimelineSlot } from "./models";
import { useTimeline } from "./timeline";
import styles from "./TimelineOverview.module.scss";

export default function TimelineOverview() {
  const timeline = useTimeline();
  const itemsPerDay = timeline.itemsPerDay;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [size, setSize] = React.useState({ width: 0, height: 0 });

  const { width, height } = size;
  // TODO resize
  React.useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (!containerRef.current) return;
      setSize({
        width: containerRef.current.clientWidth,
        height: containerRef.current.clientHeight,
      });
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const dayDuration = timeline.totalDayDuration;
  return (
    <div className={styles.placeholder} style={{ width }}>
      <div ref={containerRef} className={styles.overview}>
        {itemsPerDay.map((items, day) => (
          <DayOverview
            key={day}
            timeline={timeline}
            items={items}
            day={day}
            height={height}
          />
        ))}
        {timeline.breaks.map(breakItem => {
          const itemHeight = (breakItem.duration / dayDuration) * height;
          const shiftY =
            (breakItem.startTime.diff(timeline.startAt) / dayDuration) * height;
          return (
            <div
              key={breakItem.id}
              className={styles.break}
              style={{
                left: 0,
                right: 0,
                top: shiftY,
                height: itemHeight,
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
}

type Slot = Instance<typeof TimelineSlot>;

function DayOverview({
  items,
  timeline,
  day,
  height,
}: {
  items: Slot[];
  timeline: Timeline;
  height: number;
  day: number;
}) {
  const { data: templates } = useSlotTemplatesQuery();
  const dayDuration = timeline.totalDayDuration;
  return (
    <div style={{ width: timeline.days * 20 }}>
      {items.map(item => {
        const itemHeight = (item.duration / dayDuration) * height;
        const shiftY =
          (item.startTime.diff(timeline.startAt) / dayDuration) * height;
        const template = templates.find(t => t.abbr === item.template);
        return (
          <div
            key={item.id}
            className={styles.slot}
            style={{
              top: shiftY,
              left: day * 20,
              height: itemHeight,
              backgroundColor: template?.color || "#f1f1f1",
            }}
          >
            {template?.icon}
          </div>
        );
      })}
    </div>
  );
}

import { TimelineSlotTemplateType, TimelineSlotType } from "./models";

type TimelineSlotWithTitle = Partial<TimelineSlotType> & { title: string };

const RegexSlotDuration = /(\d+)min/;
function parseDuration(item: TimelineSlotWithTitle): TimelineSlotWithTitle {
  const { title } = item;
  const matchDuration = title.match(RegexSlotDuration);
  if (matchDuration) {
    const duration = parseInt(matchDuration[1]);
    return { title: title.replace(RegexSlotDuration, ""), duration };
  }
  return { title };
}

const RegexSlotTemplate = /^(\S+):/;
function parseTemplate(templates: TimelineSlotTemplateType[]) {
  return (item: TimelineSlotWithTitle) => {
    const { title } = item;
    const matchTemplate = title.match(RegexSlotTemplate);
    if (!matchTemplate) return item;
    const template = templates.find(t => t.abbr === matchTemplate[1]);
    if (!template) return item;
    const titre = title.replace(RegexSlotTemplate, "");
    return {
      ...item,
      title: titre.trim().length ? titre : template.title,
      template: template.abbr,
    };
  };
}

function pipe(
  item: TimelineSlotWithTitle,
  fns: Array<(item: TimelineSlotWithTitle) => TimelineSlotWithTitle>
): TimelineSlotWithTitle {
  return fns.reduce((acc, fn) => {
    const result = fn(acc);
    const merged = { ...acc, ...result };
    return { ...merged, title: merged.title.replace(/ +/g, " ").trim() };
  }, item);
}

export function parseCommand(
  command: string,
  options: { templates: TimelineSlotTemplateType[] }
): TimelineSlotWithTitle {
  const { templates } = options;
  return pipe({ title: command }, [parseTemplate(templates), parseDuration]);
}

import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Modal } from "semantic-ui-react";
import * as yup from "yup";

import { useAuth } from "@/features/auth/context";
import { createTemplate } from "@/features/timeline/api-hooks";

const TemplateSchema = yup.object().shape({
  abbr: yup.string().required(),
  title: yup.string().required(),
  duration: yup.number().required(),
  color: yup.string().required(),
  icon: yup.string(),
});

type TemplateData = {
  abbr: string;
  title: string;
  duration: number;
  color: string;
  icon: string;
};

export default function TemplateCreationModal(props: {
  open: boolean;
  onClose: () => void;
}) {
  const { userId } = useAuth();
  const form = useForm<TemplateData>({
    resolver: yupResolver(TemplateSchema),
  });

  const onSubmit = (data: TemplateData) => {
    createTemplate(userId, data);
    props.onClose();
    form.reset();
  };
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Modal.Header>Create a new template</Modal.Header>
      <Modal.Content>
        <Form
          id="template-creation-form"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Form.Field>
            <label htmlFor="abbr">Abbreviation</label>
            <input
              placeholder="cours"
              id="abbr"
              {...form.register("abbr", { required: true })}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="title">Title</label>
            <input
              placeholder="Cours"
              id="title"
              {...form.register("title", { required: true })}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="duration">Duration</label>
            <input
              placeholder="20"
              type="number"
              step={10}
              id="duration"
              {...form.register("duration", { required: true })}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="color">Color</label>
            <input
              placeholder="red"
              type="color"
              id="color"
              {...form.register("color", { required: true })}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="icon">Icon</label>
            <input placeholder="⭐" id="icon" {...form.register("icon")} />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary type="submit" form="template-creation-form">
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

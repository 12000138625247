import firebase from "firebase/compat/app";
import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";

export default function NavigationBar() {
  return (
    <Menu
      stackable
      fixed="top"
      style={{ left: 150, width: "calc(100% - 150px)" }}
    >
      <Menu.Item>{/* FIXME logo */}</Menu.Item>
      <Menu.Item as={Link} name="scripts" to="/">
        Scripts
      </Menu.Item>
      <Menu.Item as={Link} name="sessions" to="/">
        Sessions
      </Menu.Item>
      <Menu.Item
        position="right"
        name="sign-out"
        onClick={() => firebase.auth().signOut()}
      >
        Sign-out
      </Menu.Item>
    </Menu>
  );
}

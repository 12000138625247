import { Instance } from "mobx-state-tree";
import React from "react";
import { Button, Modal } from "semantic-ui-react";

import { useCreateTimelineMutation } from "./api-hooks";
import { Timeline } from "./models";
import TimelineForm from "./TimelineForm";
import { TimelineQuickFormData } from "./types";

export default function TimelineFormModal(props: {
  open: boolean;
  onClose: () => void;
  timeline: Instance<typeof Timeline> | null;
}) {
  const { timeline } = props;
  const createMutation = useCreateTimelineMutation();

  const onSubmit = (data: TimelineQuickFormData) => {
    if (props.timeline) {
      timeline.update(data);
    } else {
      createMutation.mutate(data);
    }
    props.onClose();
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Modal.Header>Create a new timeline</Modal.Header>
      <Modal.Content>
        <TimelineForm
          id="timeline-form"
          initial={{
            title: timeline?.title || "",
            days: timeline?.days || 1,
          }}
          onSubmit={onSubmit}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button primary form="timeline-form">
          {timeline ? "Save" : "Create"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

import { observable } from "mobx";
import { IAnyModelType, Instance, SnapshotIn } from "mobx-state-tree";
import React from "react";

export function useLocalObservable<Model extends IAnyModelType>(
  model: Model,
  initial: SnapshotIn<Model>
): [Instance<Model>, (store: SnapshotIn<Model>) => void] {
  const [ob, setOb] = React.useState(() =>
    observable(model.create(initial), {}, { autoBind: true })
  );

  const setSnapshot = React.useCallback(
    (snapshot: SnapshotIn<Model>) => {
      setOb(() => model.create(snapshot));
    },
    [model]
  );

  return [ob, setSnapshot];
}

import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "semantic-ui-react";
import * as yup from "yup";

import { TimelineQuickFormData } from "./types";

const TimelineQuickFormSchema = yup.object().shape({
  title: yup.string().required(),
  days: yup.number().required().min(1).max(12),
});

export default function TimelineForm(props: {
  id?: string;
  onSubmit: (data: TimelineQuickFormData) => void;
  initial?: Partial<TimelineQuickFormData>;
}) {
  const form = useForm<TimelineQuickFormData>({
    resolver: yupResolver(TimelineQuickFormSchema),
    defaultValues: props.initial,
  });

  const onSubmit = (data: TimelineQuickFormData) => {
    props.onSubmit(data);
    form.reset();
  };

  return (
    <Form id={props.id} onSubmit={form.handleSubmit(onSubmit)}>
      <Form.Group>
        <Form.Field>
          <label htmlFor="title">Title</label>
          <input required placeholder="React" {...form.register("title")} />
        </Form.Field>
        <Form.Field>
          <label htmlFor="days">Days</label>
          <input required placeholder="1" {...form.register("days")} />
        </Form.Field>
      </Form.Group>
    </Form>
  );
}

import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { Instance, SnapshotIn } from "mobx-state-tree";
import React from "react";
import { useMutation, useQuery } from "react-query";
import slugify from "slugify";
import { v4 as uuidv4 } from "uuid";

import { useAuth } from "@/features/auth/context";

import { Timeline } from "./models";
import { TimelineQuickFormData } from "./types";

type TemplateData = {
  abbr: string;
  title: string;
  duration: number;
  color: string;
  icon: string;
};

const db = getFirestore();
const collectionName = "timelineItemTemplates";
async function getItemTemplates(userId: string) {
  const col = collection(db, collectionName);
  const q = query(col, where("userId", "==", userId));
  const results = await getDocs(q);

  return results.docs.map(x => x.data());
}

export async function createTemplate(userId: string, template: TemplateData) {
  const data = {
    userId,
    ...template,
  };
  console.log(data);
  const result = await setDoc(doc(db, collectionName, uuidv4()), data);
  console.log(result);
}

export function useSlotTemplatesQuery() {
  const { userId } = useAuth();
  return useQuery(["slotTemplates", userId], () => getItemTemplates(userId!), {
    initialData: [],
    cacheTime: 4 * 60 * 1000,
    refetchOnWindowFocus: false,
  });
}

type TimelineInstance = Instance<typeof Timeline>;
export const timelineApi = {
  async getTimelines(userId: string) {
    const col = collection(db, "timelines");
    const q = query(col, where("userId", "==", userId));
    const results = await getDocs(q);
    const timelines = results.docs.map(x => ({
      id: x.id,
      title: x.get("title"),
    }));
    return timelines;
  },
  async getTimeline({
    slug,
    userId,
  }: {
    slug: string;
    userId: string;
  }): Promise<SnapshotIn<typeof Timeline>> {
    const col = collection(db, "timelines");
    const q = query(
      col,
      where("userId", "==", userId),
      where("slug", "==", slug)
    );
    const results = await getDocs(q);
    const timeline = results.docs[0].data();
    return timeline as unknown as SnapshotIn<typeof Timeline>;
  },
  async createTimeline(userId: string, timeline: TimelineQuickFormData) {
    const data = {
      id: uuidv4(),
      userId,
      title: timeline.title,
      slug: slugify(timeline.title, { lower: true }),
      breaks: [{ startTime: "12:00", endTime: "13:00" }],
    };
    await setDoc(doc(db, "timelines", uuidv4()), data);
  },
  async updateTimeline(timeline: TimelineInstance) {
    const { slug, ...rest } = timeline;
    const data = {
      slug: slug || slugify(timeline.title, { lower: true }),
      ...rest,
    };
    await setDoc(doc(db, "timelines", timeline.id), data);
  },
};

export function useTimelinesQuery() {
  const { userId } = useAuth();
  return useQuery(["timelines", userId], () =>
    userId ? timelineApi.getTimelines(userId) : []
  );
}

export function useTimelineQuery({ slug }: { slug: string }) {
  const { userId } = useAuth();
  return useQuery(["timeline", slug], () =>
    timelineApi.getTimeline({ slug, userId })
  );
}

export function useCreateTimelineMutation() {
  const { userId } = useAuth();
  return useMutation(
    (timeline: TimelineQuickFormData) =>
      timelineApi.createTimeline(userId!, timeline),
    {
      onSuccess: () => {
        console.log("success");
      },
    }
  );
}

export function useUpdateTimelineSyncMutation() {
  const [pendingChanges, setPendingChanges] =
    React.useState<TimelineInstance | null>(null);
  const timeoutRef = React.useRef<number | null>(null);

  const mutation = useMutation(
    (timeline: TimelineInstance) => timelineApi.updateTimeline(timeline),
    {
      onSuccess: () => {
        console.log("success");
      },
    }
  );

  const { mutate } = mutation;
  const commit = React.useCallback(() => {
    if (!pendingChanges) return;
    mutate(pendingChanges);
    setPendingChanges(null);
  }, [pendingChanges, mutate]);

  React.useEffect(() => {
    const timeout = setTimeout(commit, 10000);

    return () => clearTimeout(timeout);
  }, [commit]);

  React.useEffect(() => {
    if (!timeoutRef.current) return;

    clearTimeout(timeoutRef.current);
  }, []);

  return {
    mutate: setPendingChanges,
    isSync: !pendingChanges,
    isLoading: mutation.isLoading,
  };
}

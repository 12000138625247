export enum SlotShortCuts {
  SlotIncrementDuration = "SlotIncrementDuration",
  SlotDecrementDuration = "SlotDecrementDuration",
  SlotFocusNext = "SlotFocusNext",
  SlotFocusPrevious = "SlotFocusPrevious",
  SlotFocusNextDay = "SlotFocusNextDay",
  SlotFocusPreviousDay = "SlotFocusPreviousDay",
  SlotDelete = "SlotDelete",
  SlotStartUpdateTemplate = "SlotStartUpdateTemplate",
  SlotStartEditTitle = "SlotStartEditTitle",
}

export enum StatisticsShortCuts {
  ToggleMode = "ToggleMode",
}

export const ShortCuts: {
  Slot: Record<KeyboardEvent["code"], SlotShortCuts>;
  Statistics: Record<KeyboardEvent["code"], StatisticsShortCuts>;
} = {
  Slot: {
    KeyD: SlotShortCuts.SlotIncrementDuration,
    KeyS: SlotShortCuts.SlotDecrementDuration,
    KeyJ: SlotShortCuts.SlotFocusNext,
    KeyK: SlotShortCuts.SlotFocusPrevious,
    KeyL: SlotShortCuts.SlotFocusNextDay,
    KeyH: SlotShortCuts.SlotFocusPreviousDay,
    Backspace: SlotShortCuts.SlotDelete,
    KeyT: SlotShortCuts.SlotStartUpdateTemplate,
    Enter: SlotShortCuts.SlotStartEditTitle,
    ArrowUp: SlotShortCuts.SlotFocusPrevious,
    ArrowDown: SlotShortCuts.SlotFocusNext,
    ArrowLeft: SlotShortCuts.SlotFocusPreviousDay,
    ArrowRight: SlotShortCuts.SlotFocusNextDay,
  },
  Statistics: {
    KeyM: StatisticsShortCuts.ToggleMode,
  },
};

export default ShortCuts;

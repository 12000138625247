import classnames from "classnames";
import { Observer } from "mobx-react-lite";
import { onSnapshot, SnapshotOut } from "mobx-state-tree";
import React, { useEffect } from "react";
import GridLayout, { Layout } from "react-grid-layout";
import { useParams } from "react-router-dom";
import { Button } from "semantic-ui-react";
import slugify from "slugify";

import useModal from "@/hooks/useModal";
import { useElementSize } from "@/hooks/useWidthResizable";
import { useLocalObservable } from "@/utils";

import { useAuth } from "../auth/context";
import { useTimelineQuery, useUpdateTimelineSyncMutation } from "./api-hooks";
import { Timeline } from "./models";
import SlashForm from "./SlashForm";
import SlotCard from "./SlotCard";
import { TimelineProvider } from "./timeline";
import styles from "./TimelineDetail.module.scss";
import TimelineFormModal from "./TimelineFormModal";
import TimelineOverview from "./TimelineOverview";
import TimelineStatistics from "./TimelineStatistics";

function useTimeline(slug: string | undefined) {
  const timelineQuery = useTimelineQuery({ slug });
  const { userId } = useAuth();
  const [ob, setSnapshot] = useLocalObservable(Timeline, {
    title: "Script",
    slug: "script",
    userId,
  });

  const { data: timeline } = timelineQuery;

  useEffect(() => {
    if (!timeline) return;
    setSnapshot(timeline);
  }, [timeline, setSnapshot]);

  const updateTimeline = values => {
    console.log("updateTimeline", values);
  };
  useEffect(() => {
    return onSnapshot(ob, (snapshot: SnapshotOut<typeof Timeline>) =>
      updateTimeline(snapshot)
    );
  }, [updateTimeline, ob]);

  return ob;
}

export default function TimelineDetail() {
  const params = useParams();
  const { timelineSlug } = params;
  const timeline = useTimeline(timelineSlug);
  const modalEdit = useModal(false);
  const [isFullScreen, setFullScreen] = React.useState(false);

  const onLayoutChange = (layout: Layout[]) => {
    layout.forEach(item => {
      timeline.updateSlotWithLayout(item);
    });
  };

  const updateMutation = useUpdateTimelineSyncMutation();
  const { mutate } = updateMutation;

  React.useEffect(() => {
    const disposer = onSnapshot(timeline, snapshot => {
      mutate(snapshot);
    });
    return disposer;
  }, [timeline, mutate]);

  React.useEffect(() => {
    const handleFullscreenKeyDown = (e: KeyboardEvent) => {
      if (e.key === "f") {
        setFullScreen(isFullScreen => !isFullScreen);
      }
    };
    window.addEventListener("keydown", handleFullscreenKeyDown);
    return () => {
      window.removeEventListener("keydown", handleFullscreenKeyDown);
    };
  }, []);

  const gridRef = React.useRef<HTMLDivElement>(null);
  const { width } = useElementSize(gridRef.current);

  if (!timeline) {
    return <div>Timeline not found</div>;
  }

  const rowHeight = isFullScreen
    ? (document.body.clientHeight - 40) /
      (timeline.totalDayDuration / timeline.minSlotTime)
    : 20;
  console.log(rowHeight);
  const hourInPx = (rowHeight * 60) / timeline.minSlotTime;
  const marginV = 4;
  const gridWidth =
    Math.max(timeline.days * 150, width) - (isFullScreen ? 40 : 0);
  const dayWidth = gridWidth / timeline.days;
  return (
    <div>
      <TimelineProvider timeline={timeline}>
        <Observer>
          {() => (
            <div className={styles.container}>
              <main className={styles.main}>
                <TimelineStatistics />
                <h1>
                  {timeline.title} {getIcon(updateMutation)}
                  <Button
                    size="tiny"
                    style={{ marginLeft: 10 }}
                    onClick={modalEdit.handleOpen}
                  >
                    Edit
                  </Button>
                </h1>
                {/*<DayTitles days={timeline.days} style={{ width: gridWidth }} />*/}
                <GridLayout
                  innerRef={gridRef}
                  cols={timeline.days}
                  layout={timeline.layout}
                  rowHeight={rowHeight}
                  width={gridWidth}
                  compactType={null}
                  resizeHandles={["n", "s"]}
                  style={{
                    backgroundSize: `${dayWidth}px ${hourInPx}px`,
                    backgroundImage:
                      "/*repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),*/ repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%)",
                  }}
                  margin={[marginV, isFullScreen ? 0 : 2]}
                  className={classnames(styles.grid, {
                    [styles.fullscreen]: isFullScreen,
                  })}
                  onLayoutChange={onLayoutChange}
                >
                  {timeline.items.map(item => (
                    <SlotCard
                      key={item.id}
                      id={`slot-item-${item.id}`}
                      slot={item}
                    />
                  ))}
                  {timeline.breaks.map(item => (
                    <SlotCard.Break key={item.id} pause={item} />
                  ))}
                </GridLayout>
                <SlashForm />
              </main>
              <TimelineOverview />
              <TimelineFormModal
                open={modalEdit.isOpen}
                timeline={timeline}
                onClose={modalEdit.handleClose}
              />
            </div>
          )}
        </Observer>
      </TimelineProvider>
    </div>
  );
}

function getIcon<T extends { isSync: boolean; isLoading: boolean }>(state: T) {
  if (state.isLoading) {
    return <span>⏳</span>;
  }
  if (state.isSync) {
    return <span>✅</span>;
  }
  return <span>⚠️</span>;
}

export default class Time {
  public hour: number;
  public minutes: number;
  constructor(time: string) {
    const [hour, minutes] = time.split(":");
    this.hour = parseInt(hour);
    this.minutes = parseInt(minutes);
  }

  add(minutes: number): Time {
    const newMinutes = this.minutes + minutes;
    const newHour = this.hour + Math.floor(newMinutes / 60);
    const newMinutesRemainder = newMinutes % 60;
    return new Time(`${newHour}:${newMinutesRemainder}`);
  }

  isBefore(time: Time): boolean {
    return (
      this.hour < time.hour ||
      (this.hour === time.hour && this.minutes < time.minutes)
    );
  }

  isAfter(time: Time): boolean {
    return !this.isBeforeOrEquals(time);
  }

  equals(time: Time): boolean {
    return this.hour === time.hour && this.minutes === time.minutes;
  }

  diff(time: Time): number {
    return this.toMinutes() - time.toMinutes();
  }

  isBeforeOrEquals(time: Time): boolean {
    return this.isBefore(time) || this.equals(time);
  }

  isAfterOrEquals(time: Time): boolean {
    return this.isAfter(time) || this.equals(time);
  }

  isBetween(lower: Time, upper: Time): boolean {
    return this.isAfterOrEquals(lower) && this.isBeforeOrEquals(upper);
  }

  toMinutes(): number {
    return this.hour * 60 + this.minutes;
  }

  toString() {
    const hour = this.hour < 10 ? `0${this.hour}` : this.hour;
    const minutes = this.minutes < 10 ? `0${this.minutes}` : this.minutes;
    return `${hour}:${minutes}`;
  }

  copy(): Time {
    return new Time(this.toString());
  }
}
